<template>
  <div>
    <v-layout wrap justify-center >
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs11>
            <v-layout wrap justify-start>
              <v-flex lg1 md1 sm1 xs1 class=" hidden-xs-only hidden-sm-only hidden-md-only">
            <v-img src="./../../assets/images/realestate44.png" height="25%" width="60%" contain></v-img>
        </v-flex>
              <v-flex xs12 sm11 md11 lg11 pt-lg-0 pt-10 >
                <v-layout wrap justify >
                  <v-flex xs12 sm12 md12  text-center
                    ><span class="thirdhead"
                      >Empowering Real Estate Success:
                      <span class="thirdhead1"
                        >Our Key Initiatives for Excellence</span
                      ></span
                    ></v-flex
                  >
                  <v-flex xs12 text-center pt-6
                    ><span class="thirdsub"
                      >Explore our core initiatives aimed at propelling real
                      estate success to new heights. From innovative designs to
                      strategic asset buying, we are committed to delivering
                      excellence in every aspect of property development and
                      management.</span
                    ></v-flex
                  >
                </v-layout>
                <v-layout wrap justify-center >
              <v-flex xs12>
                <v-layout wrap justify-center>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-layout wrap justify-center >
                      <v-flex xs12 sm6 md6 lg6 v-for="(item, i) in items" :key="i">
                        <v-layout wrap justify-center pt-14 pl-sm-10>
                          <v-flex xs1  pt-1
                            > <v-img :src="item.img" width="25px" height="25px"></v-img></v-flex
                          >
                          <v-flex pl-5 pl-lg-3 xs11  md10 lg7   
                            ><span class="thirdsub1">{{ item.title }}</span></v-flex
                          >
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
              </v-flex>
            </v-layout>
            
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          img: require("./../../assets/images/realimg.png"),
          title: "Residential property development specialists",
        },
        {
          img: require("./../../assets/images/realimg.png"),
          title: "Expertise in commercial ventures",
        },
        {
          img: require("./../../assets/images/realimg.png"),
          title: "Strategic Land Asset buying",
        },
        {
          img: require("./../../assets/images/realimg.png"),
          title: "Project Management Concept to Completion",
        },
        {
          img: require("./../../assets/images/realimg.png"),
          title: "High yield returns",
        },
        {
          img: require("./../../assets/images/realimg.png"),
          title: "Premium, quality finishes",
        },
        {
          img: require("./../../assets/images/realimg.png"),
          title: "Innovative Designs",
        },
        {
          img: require("./../../assets/images/realimg.png"),
          title: "Building information modeling",
        },
      ],
    };
  },
};
</script>
<style>
.thirdhead {
  font-family: OutfitRegular;
  font-size: 35px;
  font-weight: 700;
  text-align: justify;
}
.thirdhead1 {
  font-family: OutfitRegular;
  font-size: 35px;
  font-weight: 700;
  color: rgba(27, 109, 193, 1);
}
.thirdsub {
  font-family: JostRegular;
  font-size: 20px;
  font-weight: 400;
  line-height: 27.2px;
  text-align: justify;
}
.thirdsub1 {
  font-family: JostRegular;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.2px;
}
</style>