<template>
    <div>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <firstSection />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <secondSection />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <thirdSection />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <fourthSection />
        
      </v-flex>
    </v-layout>
    </div>
</template>
<script>
// import axios from "axios";
import firstSection from "./firstSection.vue";
import secondSection from "./secondSection.vue";
import thirdSection from "./thirdSection.vue";
import fourthSection from "./fourthSection.vue";
export default {
  components: {
    firstSection,
    secondSection,
    thirdSection,
    fourthSection,
  },
  data() {
    return {
      
    };
  },
  beforeMount() {},
  methods: {},
};
</script>