<template>
  <div>
    <v-layout wrap justify-center pt-10>
      <v-flex xs12 sm12 md12 lg12>
        <v-layout wrap justify-center>
          <v-flex xs12 sm12 md12 lg12 px-sm-2>
            <v-layout wrap justify-center>
              <v-flex xs12 sm6 lg6 md6 pl-lg-1>
                <v-img
                  src="./../../assets/images/real2.png"
                  contain
                  style="max-width: 100%; max-height: 80%;"
                ></v-img>
              </v-flex>
              
              <v-flex xs11 sm6 lg6 md6 pt-sm-0 pt-md-5  pt-xl-0>
                <v-layout wrap justify-center >
                  <v-flex xs11 align-self-center pt-lg-16 class="secondhead"
                    ><span
                      >Transforming Visions into Reality with Innovative Design
                      and
                      <span class="secondhead1">Unparalleled Expertise</span>.</span
                    ></v-flex
                  >
                  <v-flex xs12 sm11 pt-6 text-justify
                    ><span class="secondsub"
                      >Our team is fuelled by a passion for innovation, an
                      assurance of quality and a commitment to flawless
                      workmanship. Providing a fully integrated service for our
                      clients, we focus in premium residential and commercial
                      projects and are renowned for our expertise and market
                      insight. We are committed to deliver projects on time and
                      on budget. In Kumarakom Ventures we are committed to
                      bringing our clients’ visions to life. Our design and
                      construction experts work collaboratively to achieve the
                      highest architectural standards. As pioneers in innovative
                      design, we deliver unique and unforgettable projects that
                      respect and enhance their surroundings.</span
                    ></v-flex
                  >
                  <v-flex xs12 pt-lg-10>
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-layout wrap justify-center>
                                <v-flex xs12 lg6 sm11 pt-5 pl-lg-8 v-for="(item, i) in items" :key="i">
                                    <v-layout wrap >
                                        <v-flex xs2 lg1>
                                            <v-icon color="rgba(27, 109, 193, 1)">{{ item.icon }}</v-icon>   
                                        </v-flex>
                                        <v-flex xs10  lg11>
                                            <span class="secondsub1">{{ item.title }}</span>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-layout wrap justify-end >
        <v-flex lg1 md1 sm1 xs1   class="move-up hidden-xs-only hidden-sm-only hidden-md-only">
          <v-img src="./../../assets/images/realestate44.png" height="50%" width="60%" contain></v-img>
        </v-flex>
      </v-layout>
    </v-layout>
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          icon: "mdi-domain",
          title: "Expertise in Premium Properties",
        },
        {
          icon: "mdi-domain",
          title: "Collaborative Design Approach",
        },
        {
          icon: "mdi-domain",
          title: "Innovative and Unique Designs",
        },
        {
          icon: "mdi-domain",
          title: "Timely and Budget-Friendly Projects",
        },
        
      ],
    };
  },
};
</script>
<style>
.secondhead {
  font-family: OutfitRegular;
  font-size: 28px;
  font-weight: 700;
}
.secondhead1 {
  font-family: OutfitRegular;
  font-size: 28px;
  font-weight: 700;
  color: rgba(27, 109, 193, 1);
}
.secondsub{
    font-family: JostRegular;
  font-size: 16px;
  font-weight: 400;
}
.secondsub1{
    font-family: JostRegular;
  font-size: 18px;
  font-weight: 600;
}
.move-up {
  margin-top: -200px; /* Adjust the margin as needed */
}

</style>