import { render, staticRenderFns } from "./finalSection.vue?vue&type=template&id=0cef03b0"
import script from "./finalSection.vue?vue&type=script&lang=js"
export * from "./finalSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports